import * as React from "react"
import { graphql } from "gatsby"

import Header from "../components/header"
import Footer from '../components/footer'
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <>
      <SEO title="404: Not Found" />
      <Header/>
      <div className="Error404">
          <div className="container container-main">
              <div className="content">
                  <div class="PageNotFound">
                      <span class='one'>4</span>
                      <span class='two'>0</span>
                      {/* <span class='mid'>oops!</span> */}
                      <span class='three'>4</span>
                  </div>
              </div>
          </div>
      </div>
      <Footer/>
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
